import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/preview-react/segmented-control/SegmentedControl.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const NoticeCard = makeShortcode("NoticeCard");
const LegacyPatternLink = makeShortcode("LegacyPatternLink");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/preview-react/segmented-control" storybook="https://workday.github.io/canvas-kit/?path=/story/preview-segmented-control--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=20003-18839&t=gwsyYMobzrKIOcBB-4" package="@workday/canvas-kit-preview-react" mdxType="PackageInfo" />
      <NoticeCard headingText="Segmented Control (Main) vs. Segmented Control (Preview)" linkText="Segmented Control (Main) Documentation" linkTo="https://workday.github.io/canvas-kit/?path=/docs/components-buttons-segmented-control--basic" mdxType="NoticeCard">
        <p>{`We recommend you use the Segmented Control in the Preview package
(`}<inlineCode parentName="p">{`@workday/canvas-kit-preview-react`}</inlineCode>{`) documented here on this page. The Segmented Control in the
Main package (`}<inlineCode parentName="p">{`@workday/canvas-kit-react`}</inlineCode>{`) will eventually be replaced with the Segmented Control in
the Preview package.`}</p>
      </NoticeCard>
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5d6a973efc31d5730ee39b6dd5567605/d0143/segmented-control-anatomy.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "22.43243243243243%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAs0lEQVR42k2Q2a7CMAxE+f8P5PEKHrhAk2Zpmq2kOYQuUEuWFY9nnPGJLaZSCVOl1q1RZqqLRB/RrZqWOeUVyy9KyPiYsD7R24AZA6nhJ649spMorZdZpQyDc9CEy8Mi/58IIbg/OzqlVkHtme4aIXustfTGcDv/oaVqgnFaNue5gHC4myCktPDm9l0fIsPosXpguDzantVC3XO3pMaPzSZ4jA/49bwx9tJOgPGHuSPt93gDzvk4T2hl7ZcAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a segmented control with annotation markers describing the anatomy.",
              "title": "Image of a segmented control with annotation markers describing the anatomy.",
              "src": "/static/5d6a973efc31d5730ee39b6dd5567605/50383/segmented-control-anatomy.png",
              "srcSet": ["/static/5d6a973efc31d5730ee39b6dd5567605/1efb2/segmented-control-anatomy.png 370w", "/static/5d6a973efc31d5730ee39b6dd5567605/50383/segmented-control-anatomy.png 740w", "/static/5d6a973efc31d5730ee39b6dd5567605/d0143/segmented-control-anatomy.png 1025w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Active Button:`}</strong>{` Only one segment can be active at a time. These mutually exclusive Buttons can
appear as icon only, text left, or icon left with text.`}</li>
        <li parentName="ol"><strong parentName="li">{`Default Buttons:`}</strong>{` Default control options that can be customized.`}</li>
        <li parentName="ol"><strong parentName="li">{`Alignment:`}</strong>{` The Icon Only variant can be arranged horizontally or vertically.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Only one control option can be selected and active at a time.`}</li>
        <li parentName="ul">{`Once a user selects an option, the results should be displayed immediately.`}</li>
        <li parentName="ul">{`Each Button must clearly identify its purpose. Text variants will already have their labels
displayed visually, but `}<a parentName="li" {...{
            "href": "/components/popups/tooltip"
          }}>{`tooltips`}</a>{` should be used for icon only
variants.`}</li>
        <li parentName="ul">{`All the buttons in the group to be the same size regardless of text length. Length of segments is
determined by the longest text label.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Segmented Control to switch between alternate views of similar or related content.`}</li>
        <li parentName="ul">{`Use icon-only variant when there is limited space or when the icons are intuitive and easy to
understand.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Use `}<a parentName="li" {...{
            "href": "/components/containers/tabs"
          }}>{`Tabs`}</a>{` to display different, unrelated content.`}</li>
        <li parentName="ul">{`Use Hyperlinks within a paragraph to navigate to another page. See the Button or Hyperlink section
of the `}<LegacyPatternLink href="/patterns/calls-to-action/" mdxType="LegacyPatternLink">{`Buttons and Calls to
Action`}</LegacyPatternLink>{` pattern for more information and best practices.`}</li>
        <li parentName="ul">{`Use `}<a parentName="li" {...{
            "href": "/components/inputs/checkbox"
          }}>{`Checkboxes`}</a>{` when the user is able to select multiple values from
a predefined list of 7 or less options.`}</li>
        <li parentName="ul">{`Use Multi-select Prompts when the user is able to select multiple values from a predefined list of
more than 7 options.`}</li>
        <li parentName="ul">{`Use `}<a parentName="li" {...{
            "href": "/components/inputs/radio"
          }}>{`Radio`}</a>{` when the user is able to select a single value from a
predefined list of 2 to 7 options that will not alter the page content.`}</li>
        <li parentName="ul">{`Use Single-select Prompts when the user is able to select a single value from a predefined list of
more than 7 options that will not alter the page content. Alternatively,
`}<a parentName="li" {...{
            "href": "/components/inputs/select"
          }}>{`Select`}</a>{` can also be used when there are 7 to 15 single-select options.`}</li>
      </ul>
      <h3 {...{
        "id": "dos-and-donts"
      }}>{`Dos and Don’ts`}</h3>
      <SideBySide mdxType="SideBySide">
        <Suggestion status="do" guidance="Labels should be short and succinct. If a label is too long to fit within its segment, consider using an icon- only variant." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "160px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/a3b6dfb3cc024b4393b4453b6aa8ad03/69538/seg-control-do1.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "87.5%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB8UlEQVR42r2UW2/TQBCF+/PzH3jiCRW1QgIh4AW1oVJREVWTqIQktuNL7Di+rB3Hbhu7yenMNC6UNiEREisd2bve/Xb27Iz3sKYtl8u12tT21sE2tU3fnwXeLhZQSYogihHTM8tmSKeZKJvlWGwLrHceTwJoQ5uACpbjwnHHiJRCrBK4/kQ2Wxfps0CbAL3+AK/393HRaiPNcrQvu3jz7iMM0ybgdEfg6B54eHCAVrtDwBkuu328//QZQ8sRG3YCxipFTxtKpJphwbRdsiFEEMZyfPZxK+AvMlBVFUpS/SzLleh951v+l/YArKpbzOelqI5mXpb3Wo0/6fNcivgJ8PrmBt2+Dp1u0KQ0GRgm9KEFdzwh+fDJP4/eR54v4nE/CEX2yENxdf3gqQCjOIHnB5TAGRzHoQlXApmR+SElt0E5WffrMb4sh2Ah5ervaSTAMFaUuCm+NJtoNBrQdF3GuEI6P3p48fIVTs/OkRcFZnmO7xcdHL79gKOTrxTIRCroMZB28SklLNNE8/gYURTRcSJaXMB2PJycnmGgmytgQWk0wrfzFtmkydok/SNCnvSzb0ipqTQTj9gbLjUuM/7Oi/ioUaxkbMr1TWJPOepHQG7sGyc0iz3hYyRpJiDuq6TuZ6v+VObyZv8nD//2U932h3sHfJxtZZLqOXcAAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image displaying icon only Segmented Controls, using icons indicating a switch between list, bullets, and paragraph view.",
                  "title": "Image displaying icon only Segmented Controls, using icons indicating a switch between list, bullets, and paragraph view.",
                  "src": "/static/a3b6dfb3cc024b4393b4453b6aa8ad03/69538/seg-control-do1.png",
                  "srcSet": ["/static/a3b6dfb3cc024b4393b4453b6aa8ad03/69538/seg-control-do1.png 160w"],
                  "sizes": "(max-width: 160px) 100vw, 160px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <Suggestion status="error" guidance="Wrap or truncate text" mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "370px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/005f335804ba79d407972431a7311231/1efb2/seg-control-dont1.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "37.83783783783784%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABW0lEQVR42m2S6XKDMAyEef8n648cNCThSCA0R5uDyxzmCMxWEqXtTMvMjuyPtWxLNsqqgq5rUoNKaxRlRbEWxpFVVBqamcy1qKz0l6+RyPPu+YTh7fZYmibmiwX8Q4gky7F19nhd21htHAThESovYW1dmNYWtrfH9fZApgo4no+5acHZ+bh83JAXJYxU5UizTJTRWOWFxDRTFEk0ZyOzhFlOnuIvYzVNC6NuGvTDIGraVtR2HQZm/YC27YR136z/xzcyHtMJFQ5hiNPpLLvcoxgP0i4I8XZ6R5ykokc8suP5FyOfJ+yCKE6k/gbos20bruvKDnzVnK45W66kbjwu6Hq84GVmwqK6KroqszhOxbexvdHHCblL3Bg/CKRj11skSbn4XOwkVZQslUZwk1YbF9d7RCfMcKO4pmSefxCPNIWbMD0PBtNz4Jpwfad/lW6IjfX8YZOvlXXPvscnm9lc5swPcuwAAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image displaying a text-based segemnted control with each category taking more than one line or needing to be truncated with an elipses.",
                  "title": "Image displaying a text-based segemnted control with each category taking more than one line or needing to be truncated with an elipses.",
                  "src": "/static/005f335804ba79d407972431a7311231/1efb2/seg-control-dont1.png",
                  "srcSet": ["/static/005f335804ba79d407972431a7311231/1efb2/seg-control-dont1.png 370w"],
                  "sizes": "(max-width: 370px) 100vw, 370px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <SideBySide mdxType="SideBySide">
        <Suggestion status="do" guidance="Segmented buttons should have adequate margins from the edge of the viewport or frame. The container shouldn’t reach the edge of the viewport." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "334px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/6e105e0758513d9e2b4c617e359549cc/86d36/seg-control-do2.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "77.24550898203593%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAClUlEQVR42p1Sy07bQBT1B4Eoe5JIwAqx4iFWTUpJmlCJH+i2v4DEqoUkFaBK3bSbdtVu6K5qIyEwhEAAP2MbP4Id24lzemcgVVIWFbV1NMd35h6fe+cK/X4f7AmCAJIk/Rd83+caTEtgRBRFlEolLC4uYmlpaQTLy8sj+Huf5eTzedRqNS4qMNWNjQ2k02nMz89jbm5uBLOzs5ienuaYmZl5sM9yMpkMCoUCoiiC0G63sbKyglQqxUXZOkA6ncHkk0mMjY1hfHwcExMTI/upqSmk7/nCwgJ0XYfgeR6y2Sw2Nzext7eLSqWMne1tlCtVlHfeoErf1eo7ildQLjNepVgF23Rmd/89dvf2sbW1hVwuB03TIDiOw3tgWSb8IESnE/JeIInQDzUkgU7dTjB4/KCDWz/g/MfXL5Drxwgoh92BLMtDgoaJa1mB49gIYuCwaUGUXByILZxrHgbT0DJNKq3F+dvXr3Dw6QOCMEaxWBwVvNZlfiiKYvpjB36HuY2461u/Q7zD43G3y5vPeBhG6CKBbhsorZegkKE/gmdmE3bYhqYbMKwbGKaFFrm2bmzYdIatJoHFGbdsilk2HMppuhKKA0HP9fD0WQ4ff33DsdqELCk4Oa2jeXmJG0pSVB3iaQP1RhMqlcoET+rnHGcUO1Wv8PnoO7Jrq9BUFYLrulhde46f8hHUtgmVBK4kGZKiUr8s6Ky3xGVFg9a6cy8R55A1qJ6Jw1Yd+WIBqjJUct28gNcNYNsuHBolh5zbjstX12vfwfUefHuxj4vhkpkgcyiaDbhd/07wPvFfYGfd+BYN9woFcqiwW3bJReFFgQt6vccJOkOCpZfrUFjJSZLAMAzEvZgGgN7kkaCh7yZdmDSfvV4PvwGd+dvNRWE6kwAAAABJRU5ErkJggg==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image highlighting the margins around a segmented control container on a mobile device.",
                  "title": "Image highlighting the margins around a segmented control container on a mobile device.",
                  "src": "/static/6e105e0758513d9e2b4c617e359549cc/86d36/seg-control-do2.png",
                  "srcSet": ["/static/6e105e0758513d9e2b4c617e359549cc/86d36/seg-control-do2.png 334w"],
                  "sizes": "(max-width: 334px) 100vw, 334px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <Suggestion status="error" guidance="Avoid using a segmented control to enable actions, such as adding, removing, or editing content." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "334px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/c1edf46473bd3e9068aa88c8048e706c/86d36/seg-control-dont2.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "77.24550898203593%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAi0lEQVR42u2STQvCMAyG9///3FhhgrCLYCtSbdJ09eLoa8xZtosHkQVeyAc8PId0+HJ1OxCttY/5HUMpMxIxiAX3RBbirPtiN87aS0HW1PpYBz6XBVEBiQjeBwzOwYdgoMs14nQO6N2IfhhxOE6It7RtWOaKxGyWZqdGLGJGnEVnMeN3Ng33x/5T4AsnK6ONoZIERQAAAABJRU5ErkJggg==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image showing segmented controls being used as action buttons, something to avoid with segmented control.",
                  "title": "Image showing segmented controls being used as action buttons, something to avoid with segmented control.",
                  "src": "/static/c1edf46473bd3e9068aa88c8048e706c/86d36/seg-control-dont2.png",
                  "srcSet": ["/static/c1edf46473bd3e9068aa88c8048e706c/86d36/seg-control-dont2.png 334w"],
                  "sizes": "(max-width: 334px) 100vw, 334px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each button in the Segmented Control must have a focus indicator that is highly visible against the
background and against the non-focused state. Refer to
`}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more information.`}</p>
      <p>{`Segmented Control must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus each button element`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: activate focused button element`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Segmented Control must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The Buttons in the control are related to one another, and part of a group`}</li>
        <li parentName="ul">{`The functionality of each Button in the set (E.g. “Day View” or “List View” communicate
functionality)`}</li>
        <li parentName="ul">{`Which Button in the set is currently selected`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Accessible name for the group describing the purpose of the Segmented Control`}</li>
        <li parentName="ul">{`When using Icon Only Button, an accessible name for each Icon Button in the Segmented Control`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`An ARIA `}<inlineCode parentName="li">{`role=”group”`}</inlineCode>{` attribute is used for grouping the button elements together, then use an
`}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string to clearly describe the purpose of the segmented control`}</li>
        <li parentName="ul">{`Icons used in text buttons may be considered redundant or decorative. Alternative text is most
likely unnecessary, and not recommended`}</li>
        <li parentName="ul">{`Icon-only buttons must have an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string, and can be paired with an accessible tooltip
that displays both on hover and focus`}</li>
        <li parentName="ul">{`[Included in component]`}{` All buttons are required to have an `}<inlineCode parentName="li">{`aria-pressed="false"`}</inlineCode>{` attribute,
except for the currently selected button set to a value of `}<inlineCode parentName="li">{`“true”`}</inlineCode></li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`If the content exceeds the specified max-width, consider making your labels more concise, or use
an icon-only variant.`}</li>
        <li parentName="ul">{`Text in a segmented control should never truncate or wrap.`}</li>
        <li parentName="ul">{`See the `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/buttons-ctas"
          }}>{`Buttons and Calls to Action`}</a>{` page in the UI Text section
of the Content Style Guide for button language guidelines.`}</li>
        <li parentName="ul">{`When writing Tooltips to pair with Segmented Control, refer to the
`}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/tooltips"
          }}>{`Tooltips`}</a>{` section of the Content Style Guide.`}</li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      